<template lang="pug">
.lot-item__error(:class="isAuth && 'auth'")
  .lot-error-content
    .info-title К сожалению, тендер не найден
    .info-content Возможно, произошла непредвиденная ошибка, или Вы воспользовались неисправной ссылкой
    ui-button.button(type="secondary" @click="goToMainPage") Перейти на главную
  .lot-error-content.empty
    el-empty(description="Тендер не найден")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { getMainPageNavigateRoute } from "~/router/auth";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import UiButton from "@/components/ui/button/UiButton.vue";

export default defineComponent({
  name: "LotItemError",
  components: {
    UiButton,
  },
  setup() {

    const router = useRouter();

    function goToMainPage() {
      router.push(getMainPageNavigateRoute());
    }

    const { isAuth } = useUserAccess();

    return {
      isAuth,
      goToMainPage,
    }
  }
})
</script>

<style scoped lang="scss">
.lot-item__error {
  --error-card__size__info-title: 24px;
  --error-card__size__info-content: 16px;

  max-width: var(--main-content-width);
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--main-color-white);
  border-radius: 10px;
  box-shadow: 0 0 20px 10px rgba(222, 222, 222, 0.5);
  padding: 32px 40px;

  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: space-between;

  height: 300px;

  .lot-error-content {
    display: flex;
    flex-flow: column;
    gap: 32px;

    .button {
      width: fit-content;
    }

    .info-title {
      color: #409eff;
      font-size: var(--error-card__size__info-title);
      font-weight: bold;
    }

    .info-content {
      color: #324059;
      font-size: var(--error-card__size__info-content);
      font-weight: 300;
    }
  }
}

.lot-item__error:not(.auth) {

  @media(max-width: 640px) {
    --error-card__size__info-title: 20px;
    --error-card__size__info-content: 14px;
  }

  @media(max-width: 520px) {
    height: unset;
    padding: 32px 32px;

    .lot-error-content { gap: 16px; }
    .lot-error-content.empty { display: none; }
  }
}
</style>
