<template lang="pug">
#cost.base-detail-table(:class="isAuth && 'auth'")
  .more-info__line
    span.card-label Начальная (максимальная) цена контракта
    span.card-value {{ lotFullInfo?.cost.original.cost ? costFormatterExtended(lotFullInfo?.cost.original) : 'Информация отсутствует' }}
  .more-info__line
    span.card-label Обеспечение заявки
    span.card-value {{ lotFullInfo?.costAppGuarantee.original.cost ? costFormatterExtended(lotFullInfo?.costAppGuarantee.original) : 'Информация отсутствует' }}
  .more-info__line
    span.card-label Обеспечение исполнения контракта
    span.card-value {{ lotFullInfo?.costContractGuarantee.original.cost ? costFormatterExtended(lotFullInfo?.costContractGuarantee.original) : 'Информация отсутствует' }}
  .more-info__line
    span.card-label.hide-on-520 Действия
    span.card-value.common-info__actions
      ui-button.adaptive-button(:size="isAuth ? 'medium' : 'large'" @click="openTenderService(lotFullInfo, 'bankGuarantee')") Заказать банковскую гарантию
      ui-button.adaptive-button(:size="isAuth ? 'medium' : 'large'" @click="openTenderService(lotFullInfo, 'credit')") Заказать займ на обеспечение
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { costFormatterExtended } from "@/utils/formatter/costFormatter";
import { useTenderService } from "@/use/tenderService/useTenderService";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import type { PropType } from "vue";
import type { LotDetailInterface } from "@/stores/search/LotItemInterface";

import UiButton from "@/components/ui/button/UiButton.vue";

export default defineComponent({
  name: "2Cost",
  methods: {
    costFormatterExtended,
  },
  components: {
    UiButton,
  },
  props: {
    lotFullInfo: Object as PropType<LotDetailInterface>,
    default: () => ({}),
  },
  setup() {

    const { isAuth } = useUserAccess();
    const { openTenderService } = useTenderService();

    return {
      isAuth,
      openTenderService,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';
</style>
