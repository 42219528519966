<template lang="pug">
#lot-detail-page(:class="isAuth && 'auth'")
  .lot-detail-info
    .panel-action(v-if="lotFullInfo")
      ui-button(type="secondary" :icon-left="UiIconNames.Chevron_Up" @click="expansiveAll(false)") Свернуть панели
      ui-button(type="secondary" :icon-left="UiIconNames.Chevron_Down" @click="expansiveAll(true)") Развернуть панели
      ui-button.margin-right(v-if="!isCrawler" type="secondary" @click="scrollToSimilar") Показать похожие лоты
      .multi-button(v-if="!isCrawler && lotFullInfo?.isMultipleLots")
        ui-tag(size="small" name="Многолотовая закупка" @click="scrollToMultiple")
        icon-button(as-button button-type="secondary" :icon="UiIconNames.Icon_SortDownArrow" tooltip-title="Показать список лотов" @click="scrollToMultiple")

    lot-item-loading(v-if="loader")
    lot-item-error(v-else-if="error")

    lot-detail-card(v-else-if="lotFullInfo" :details="lotFullInfo")
    call-for-registration(v-if="!isAuth")
    lot-detail-panels(v-if="lotFullInfo" :details="lotFullInfo" ref="detailsPanels")

  ClientOnly(v-if="!isCrawler")
    call-for-registration(v-if="!isAuth && lotFullInfo" mini)

    #additional-lots(v-if="lotFullInfo")
      ui-tabs.tabs(:model-value="activeTab" large :tabs="tabs" @update:modelValue="switchTab")
        .similar-actions
          ui-button(v-if="activeTab === 'similar' && similarData?.length && isAuth" type="secondary" @click="showAllSimilarLots") Показать все похожие лоты
          ui-warning(v-if="activeTab === 'similar' && similarData?.length" placement="bottom-end")
            template(#warning)
              span.warning-label Как это работает?
            .warning-content
              span Система подбирает лоты по региону и схожей отрасли.
              span(v-if="isAuth") Показаны лоты с регионом <b>"{{ similarData[0]?.region?.title }}"</b> и отраслью <b>"{{ similarLotsIndustry?.title }}"</b>
              span.orange(v-if="isAuth") Функционал запущен в тестовом режиме
              span.orange(v-if="!isAuth") Зарегистрируйтесь или авторизуйтесь для появления более подробной информации и просмотра всех похожих лотов

      similar-lots(v-if="activeTab === 'similar'" :loader="similarLoader" :error="similarError" :data="similarData")
      multiple-lots(v-else :loader="multipleLoader" :error="multipleError" :data="multipleData" :lotId="lotFullInfo?.lotId" :tender-info="lotFullInfo")

  system-capabilities(v-if="!isAuth" from-card)

  ClientOnly
    support-block(v-if="!isAuth" from-card)
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useRoute } from "vue-router";
import { useApi } from "~/use/api/useApi";
import { scrollIntoView } from "~/utils/scroller/documentScroller";
import { storeToRefs } from "pinia";

import UiButton from "~/components/ui/button/UiButton.vue";
import UiTag from "~/components/ui/tag/UiTag.vue";
import IconButton from "~/components/ui/button/IconButton.vue";
import LotItemLoading from "~/components/pages/detail/lot/LotItemLoading.vue";
import LotItemError from "~/components/pages/detail/lot/LotItemError.vue";
import LotDetailCard from "@/components/pages/detail/lot/LotDetailCard.vue";
import LotDetailPanels from "~/components/pages/detail/lot/LotDetailPanels.vue";
import CallForRegistration from "@/components/pages/landing/CallForRegistration.vue";
import UiTabs from "~/components/ui/tab/UiTabs.vue";
import UiWarning from "~/components/ui/tooltip/UiWarning.vue";
import SimilarLots from "~/components/pages/detail/similar/SimilarLots.vue";
import MultipleLots from "~/components/pages/detail/similar/MultipleLots.vue";
import SystemCapabilities from "@/components/pages/landing/SystemCapabilities.vue";
import SupportBlock from "@/components/pages/landing/SupportBlock.vue";

import { Tabs } from "~/router/tabs";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import type { LotDetailInterface, LotItemInterface } from "~/stores/search/LotItemInterface";
import useManualsStore, { findLeafInTree, treeToIds } from "~/stores/manuals/useManualsStore";
import useSearchStore from "~/stores/search/useSearchStore";

export default defineComponent({
  name: "LotDetailPage",
  components: {
    IconButton,
    UiTabs,
    MultipleLots,
    LotDetailPanels,
    LotItemError,
    LotItemLoading,
    UiButton,
    SimilarLots,
    LotDetailCard,
    CallForRegistration,
    SystemCapabilities,
    SupportBlock,
    UiWarning,
    UiTag,
  },
  setup() {

    const route = useRoute();

    const loader = ref(true);
    const error = ref(false);

    const lotFullInfo = ref<LotDetailInterface>();
    const detailsPanels = ref();

    const activeTab = ref('similar');

    const tabs = computed(() => [
      { name: 'Похожие закупки', key: 'similar' },
      lotFullInfo.value?.isMultipleLots && { name: 'Список лотов', key: 'others' },
    ].filter(Boolean))

    const similarLoader = ref(true);
    const similarError = ref(false);

    const similarData = ref<LotItemInterface[]>([]);
    const similarCriteria = ref();

    const multipleLoader = ref(true);
    const multipleError = ref(false);

    const multipleData = ref<LotItemInterface[]>([]);

    const manualsStore = useManualsStore();
    const similarLotsIndustry = computed(() => manualsStore.computedIndustryList.find(e => e.id === similarCriteria.value?.industryIds?.[0]))

    const { isAuth } = useUserAccess();

    const searchStore = useSearchStore();
    const { searchPageMode, searchFormDataFilters } = storeToRefs(searchStore)
    const { resetFullSearchData } = searchStore;

    const isCrawler = useState('isCrawler');

    function getLotFullInfo() {
      error.value = false
      loader.value = true

      return (route.name === Tabs.LotDetail
        ? useApi().lots.byTLot(Number(route.params.tLotId))
        : useApi().lots.byGovRu(route.params.lotNumber as string, route.params.govRuId as string))
    }

    function expansiveAll(value: boolean) {
      if (detailsPanels.value) detailsPanels.value.togglePanels(value)
    }

    function switchTab(t: 'similar' | 'others') {
      activeTab.value = t
      if (t === 'others' && !multipleData.value.length) getMultipleLots(lotFullInfo.value?.tenderId)
    }

    function scrollToSimilar() {
      switchTab('similar')
      scrollIntoView('additional-lots', 'start')
    }

    function scrollToMultiple() {
      switchTab('others')
      scrollIntoView('additional-lots', 'start')
    }

    /** show all similar lots in 'search' tab */
    function showAllSimilarLots() {
      if (!similarCriteria.value.industryIds.length) return

      const leaf = findLeafInTree(similarCriteria.value.industryIds[0], manualsStore.industries)
      const ids = treeToIds([leaf])

      searchPageMode.value = 'fullSearchMode'
      resetFullSearchData()

      searchFormDataFilters.value.industryIds = ids
      searchFormDataFilters.value.places = [similarCriteria.value.places]

      navigateTo({ name: Tabs.Search }, { open: { target: '_blank' }} )
    }

    getLotFullInfo()
      .then((response) => {
        if (response) {
          lotFullInfo.value = response
          if (!isCrawler.value) getSimilarLots(response?.lotId)
        }
      })
      .catch(() => error.value = true)
      .finally(() => loader.value = false)

    /** SIMILAR - param lotId */
    function getSimilarLots(id: number) {
      similarLoader.value = true

      useApi().lots.getSimilarLots(id)
        .then((response: any) => {
          similarData.value = response?.data
          similarCriteria.value = response?.searchCriteria
        })
        .catch(() => similarError.value = true)
        .finally(() => similarLoader.value = false)
    }

    /** MULTIPLE - param tenderId */
    function getMultipleLots(id: number) {
      multipleLoader.value = true

      useApi().lots.getMultipleLotCards(id)
        .then((response: any) => multipleData.value = response.sort((a: any, b: any) => a?.lotNumber - b?.lotNumber))
        .catch(() => multipleError.value = true)
        .finally(() => multipleLoader.value = false)
    }

    /** HEAD INFO */
    useHead(computed(() => ({
      title: lotFullInfo.value?.lotTitle ? `Тендер №${lotFullInfo.value?.govRuId}: ${lotFullInfo.value?.lotTitle}${ lotFullInfo.value?.lotNumber === 1 ? '' : ', лот №' + lotFullInfo.value?.lotNumber } - ТендерМонитор` : 'О лоте',
      meta: [
        { name: 'description', content: () => lotFullInfo.value?.lotTitle ? `Закупка №${lotFullInfo.value?.govRuId}: ${lotFullInfo.value?.lotTitle}${ lotFullInfo.value?.lotNumber === 1 ? '' : ', лот №' + lotFullInfo.value?.lotNumber } - ТендерМонитор` : 'О лоте' }
      ],
      link: [
        { rel: 'canonical', href: `https://tenmon.ru/search/detail/${lotFullInfo.value?.lotId}` },
      ],
    })));

    return {
      loader,
      error,
      lotFullInfo,
      isAuth,
      detailsPanels,

      tabs,
      activeTab,

      similarLoader,
      similarError,
      similarData,
      similarLotsIndustry,

      multipleLoader,
      multipleError,
      multipleData,
      isCrawler,

      expansiveAll,
      scrollToSimilar,
      scrollToMultiple,
      switchTab,
      showAllSimilarLots,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#lot-detail-page.auth {
  @include page-view;
  padding-top: 32px;
}

#lot-detail-page:not(.auth) {
  @include adaptive-view;

  display: flex;
  flex-flow: column;
  gap: 32px;
  padding: 32px 24px;
}

.lot-detail-info {
  display: flex;
  flex-flow: column;
  gap: 20px;
}

.panel-action {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;

  .multi-button {
    display: flex;
    flex-flow: row;
    gap: 8px;
  }

  .margin-right {
    margin-right: auto;
  }
}

#additional-lots {
  margin-top: 60px;
  scroll-margin-top: 80px;

  display: flex;
  flex-flow: column;
  gap: 16px;

  :deep(.tabs) {
    align-items: center;
  }

  .similar-actions {
    margin-left: auto;

    display: flex;
    flex-flow: row;
    gap: 16px;
  }

  .warning-label {
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    color: var(--main-warning-color);
  }

  .warning-content {
    display: flex;
    flex-flow: column;
    gap: 8px;
    max-width: 320px;

    .orange {
      color: var(--main-warning-color);
    }
  }
}

#lot-detail-page:not(.auth) {

  @media(max-width: 620px) {
    #additional-lots {
      margin-top: 0;

      :deep(.tabs) {
        .tab { font-size: 14px; }
      }
    }
  }

  @media(max-width: 380px) {
    padding: 24px 12px;
  }
}
</style>
