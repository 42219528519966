<template lang="pug">
.lot-detail-panels
  template(v-for="(panel, index) of panels" :key="panel.name")
    expansive-panel(
      v-if="details && !panel.hide"
      :id="panel.id"
      v-model:expansive="expansive[index]"
    )
      template(#header) {{ panel.name }}
      template(#content)
        component(:is="panel.component" v-bind="panel.props")
</template>

<script lang="ts">
import { defineComponent, ref, shallowRef } from 'vue'

import type { PropType } from 'vue';
import type { LotDetailInterface } from "@/stores/search/LotItemInterface";

import ExpansivePanel from "~/components/ui/dropdown/ExpansivePanel.vue";
import CommonInfo from "~/components/pages/detail/info/1_0_CommonInfo.vue";
import Customers from "~/components/pages/detail/info/1_1_Customers.vue";
import Cost from "~/components/pages/detail/info/2_Cost.vue";
import ProcurementProcedure from "~/components/pages/detail/info/3_ProcurementProcedure.vue";
import HostingOrganization from "~/components/pages/detail/info/4_HostingOrganization.vue";
import RequirementsParticipants from "~/components/pages/detail/info/5_RequirementsParticipants.vue";
import PurchaseObjects from "~/components/pages/detail/info/6_PurchaseObjects.vue";
import Documents from "~/components/pages/detail/info/Documentation.vue";
import Results from "~/components/pages/detail/info/9_Results.vue";
import Contracts from "~/components/pages/detail/info/10_Contracts.vue";

export default defineComponent({
  name: "LotDetailPanels",
  components: {
    ExpansivePanel,
    CommonInfo,
    Customers,
    Cost,
    ProcurementProcedure,
    HostingOrganization,
    RequirementsParticipants,
    PurchaseObjects,
    Documents,
    Results,
    Contracts,
  },
  props: {
    details: {
      type: Object as PropType<LotDetailInterface>,
      default: () => ({}),
    },
  },
  setup(props) {

    const panels = useState(() => shallowRef([] as any));
    const expansive = ref(new Array(11).fill(true))

    function initPanels(info: LotDetailInterface) {
      panels.value = [
        {name: 'Общая информация о закупке', component: 'CommonInfo', props: {lotFullInfo: info}},
        {name: 'Заказчики', component: 'Customers', props: {customers: info?.customers}, hide: (info?.customers?.length || 0) <= 1, id: 'customers-list'},
        {name: 'Начальная (максимальная) цена контракта, цена обеспечений', component: 'Cost', props: {lotFullInfo: info}},
        {name: 'Информация о процедуре закупки', component: 'ProcurementProcedure', props: {lotFullInfo: info}},
        {name: 'Информация об организации, осуществляющей определение поставщика (подрядчика, исполнителя)', component: 'HostingOrganization', props: {lotFullInfo: info}},
        {name: 'Преимущества, требования к участникам', component: 'RequirementsParticipants', props: {lotFullInfo: info}},
        {name: 'Информация об объектах закупки', component: 'PurchaseObjects', props: {purchaseObjects: info?.purchaseObjectsFull}},
        {name: 'Документация закупки', component: 'Documents', props: {docs: info?.files, lotId: info?.lotId, statusId: info?.status?.id}},
        {name: 'Протоколы', component: 'Documents', props: {docs: info?.protocols, lotId: info?.lotId, statusId: info?.status?.id}},
        {name: 'Итоги', component: 'Results', props: {results: info?.results}},
        {name: 'Контракты', component: 'Contracts', props: {contracts: info?.contracts}},
      ];
    }

    initPanels(props.details)

    function togglePanels(value: boolean) {
      expansive.value = expansive.value.map(() => value)
    }

    return {
      panels,
      expansive,
      togglePanels,     // для внешней компоненты
    }
  }
})
</script>

<style scoped lang="scss">
.lot-detail-panels {
  display: flex;
  flex-flow: column;
  gap: 20px;
}
</style>
