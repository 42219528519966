<template lang="pug">
#hosting-organization.base-detail-table(:class="isAuth && 'auth'")
  .more-info__line
    span.card-label Организация, осуществляющая размещение
    span.card-value {{ lotFullInfo?.organizationResponsible?.title || 'Информация отсутствует' }}
  .more-info__line
    span.card-label Почтовый адрес
    span.card-value {{ lotFullInfo?.organizationResponsible?.postalAddress || 'Информация отсутствует' }}
  .more-info__line
    span.card-label Место нахождения
    span.card-value {{ lotFullInfo?.organizationResponsible?.legalAddress || 'Информация отсутствует' }}
  .more-info__line(v-if="isAuth")
    span.card-label Ответственное должностное лицо
    span.card-value {{ lotFullInfo?.contactPerson?.fio || 'Информация отсутствует' }}
  .more-info__line(v-if="isAuth")
    span.card-label Адрес электронной почты
    span.card-value {{ lotFullInfo?.contactPerson?.email || 'Информация отсутствует' }}
  .more-info__line(v-if="isAuth")
    span.card-label Номер контактного телефона
    span.card-value {{ lotFullInfo?.contactPerson?.phone || 'Информация отсутствует' }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import type { PropType } from "vue";
import type { LotDetailInterface } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "4HostingOrganization",
  props: {
    lotFullInfo: Object as PropType<LotDetailInterface>,
  },
  setup() {

    const { isAuth } = useUserAccess();

    return {
      isAuth,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';
</style>
