<template lang="pug">
.no-data(v-if="!docs") Данные отсутствуют
.lot-detail-table(v-else :class="isAuth && 'auth'")
  .header.grid-layout.side-padding
    .td.header-name
      span.full-view Название документа
      span.adaptive-view Документ
      form-input(
        v-if="isAuth && statusId === LotStatusAppSubmitting"
        :model-value="docsRequest"
        placeholder="Поиск в документах"
        @update:modelValue="onFTS"
      )
    .td.adaptive-view Подробности
    .td.full-view Редакция
    .td.full-view Дата публикации
    .td.full-view Статус

  .load-files(v-if="ftsLoader")
    ui-loader(inline mini label="Ищем в файлах документации")
  .empty-files(v-else-if="!files.length")
    span По Вашему запросу файлов документаций не найдено. Пожалуйста, уточните запрос
    span.show-all-link(@click="clearDocsSearch") Показать все файлы
  template(v-else)
    .table-row.grid-layout.side-padding(
      v-for="(file, index) of files"
      v-show="file.isActual || showArchived"
      :key="index"
    )
      .td.doc-name
        .doc-title(:class="file.externalLink && '_clickable'")
          ui-icon.doc-icon(:icon="UiIconNames.Icon_DocumentCheck" :size="20")
          register-tooltip(v-if="!isAuth" label="Скачивание документов доступно в личном кабинете")
            .link {{ file.title }}
          a.link(v-else-if="file.externalLink" target="_blank" :download="true" :href="file.externalLink") {{ file.title }}
          div(v-else) {{ file.title }}
        .doc-fts(v-if="file.fts" v-html="file.fts")

      .td.info.adaptive-view
        .info-row
          span.label Дата публикации:
          span {{ formatDateTime(file.documentDate) }}
        .info-row
          span.label Редакция:
          span {{ file.version }}
          span(:class="!file.isActual && '_archived'" style="padding-left: 4px;") ({{ file.isActual ? 'Действующая редакция' : 'Недействующая редакция' }})
      .td.full-view {{ file.version }}
      .td.full-view {{ formatDateTime(file.documentDate) }}
      .td.full-view(:class="!file.isActual && '_archived'") {{ file.isActual ? 'Действующая редакция' : 'Недействующая редакция' }}

    .table-row.side-padding
      .td.actions
        ui-button.adaptive-button(v-if="isAuth" @click="downloadFiles" :loader="downloadLoader" :icon-left="UiIconNames.Icon_Download") Скачать
        register-tooltip(v-else label="Скачивание документов доступно в личном кабинете")
          ui-button.adaptive-button(@click="downloadFiles" :loader="downloadLoader" :icon-left="UiIconNames.Icon_Download") Скачать
        ui-checkbox(v-if="hasArchived" v-model="downloadWithArchived" id="downloadFiles" label="Только действующие редакции" hint="По умолчанию скачиваются все документы. Поставьте галочку, если необходимо скачать только действующие редакции")
        ui-button.adaptive-button(v-if="hasArchived" type="secondary" style="margin-left: auto;" @click="toggleShowArchived") {{ !showArchived ? 'Показать недействующие редакции' : 'Скрыть недействующие редакции'}}
</template>

<script lang="ts">
import { defineComponent, ref, computed, } from "vue";
import { formatDateTime } from "@/utils/formatter/dateFormatter";
import { downloadFromLink } from "@/utils/download/fileDownloader";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { useAbort, useApi } from "~/use/api/useApi";
import { useDebounceFn } from "@vueuse/core";

import type { PropType } from "vue";
import type { FileLDI } from "@/stores/search/LotItemInterface";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import RegisterTooltip from "~/components/other/RegisterTooltip.vue";
import FormInput from "~/components/ui/form/input/FormInput.vue";
import UiLoader from "~/components/ui/loader/UiLoader.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import { LotStatusAppSubmitting } from "~/const/statuses";

export default defineComponent({
  name: "7_1Documentation",
  components: {
    UiLoader,
    FormInput,
    UiIcon,
    UiButton,
    UiCheckbox,
    RegisterTooltip,
  },
  props: {
    lotId: {
      type: Number,
      default: 0,
    },
    docs: {
      type: Array as PropType<FileLDI[]>,
      default: () => ([]),
    },
    statusId: {
      type: Number,
      default: 0,
    },
  },
  setup(props: any) {

    const showArchived = ref(false);

    const downloadWithArchived = ref(false);
    const downloadLoader = ref(false);

    // поиск текста в файлах документации
    const docsRequest = ref('');
    const ftsData = ref([]);
    const ftsLoader = ref(false);
    const ftsRequest = ref();

    const { isAuth } = useUserAccess();

    const hasArchived = computed(() => props.docs.filter((file: FileLDI) => !file.isActual).length > 0);
    const files = computed(() =>
      !isAuth.value || !docsRequest.value
        ? props.docs
        : props.docs.map(e => {
          const ftsArray = ftsData.value.find(f => f.fileId === e.fileId)?.occurrences || [];
          const fts = ftsArray.length ? "... " + ftsArray[0] + " ..." : null

          return { ...e, fts: fts }
        }).filter(e => !!e.fts)
    )

    function toggleShowArchived() {
      showArchived.value = !showArchived.value;
    }

    function downloadFiles() {
      if (isAuth.value) {
        downloadLoader.value = true;

        props.docs.forEach((doc: FileLDI, _index: number) => {
          if (!downloadWithArchived.value || doc.isActual) downloadFromLink(doc.externalLink)
        })

        setTimeout(() => downloadLoader.value = false, 1500)
      }
    }

    function getDocumentationFTS() {
      if (ftsRequest.value) ftsRequest.value.cancel()

      if (!docsRequest.value) {
        ftsData.value = []
        ftsLoader.value = false
      } else {
        const { signal, abort, } = useAbort();
        ftsRequest.value = { cancel: abort };

        useApi().lots.getDocsFTS(props.lotId, { docsSearch: { keywords: [docsRequest.value] }}, signal)
          .then((data) => ftsData.value = data)
          .catch(() => ftsData.value = [])
          .finally(() => {
            if (!signal.aborted) {
              ftsLoader.value = false
              ftsRequest.value = null;
            }
          })
      }
    }

    const onFilter = useDebounceFn(getDocumentationFTS, 400);
    const onFTS = (value: string) => {
      docsRequest.value = value
      if (value) ftsLoader.value = true
      onFilter()
    }

    const clearDocsSearch = () => { docsRequest.value = '' }

    return {
      isAuth,
      files,
      docsRequest,
      showArchived,
      hasArchived,
      downloadWithArchived,
      downloadLoader,
      ftsLoader,
      onFTS,
      formatDateTime,
      toggleShowArchived,
      downloadFiles,
      clearDocsSearch,
      LotStatusAppSubmitting,
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/links";
@import '@/assets/styles/table/lotDetail';
@import '@/assets/styles/mixin/fonts';

.show-all-link {
  @include link-mixin;

  &:hover {
    font-weight: bold;
  }
}

.lot-detail-table {
  .grid-layout {
    display: grid;
    grid-template-columns: 5fr minmax(100px, 1fr) minmax(160px, 2fr) minmax(200px, 2fr);
  }

  .info {
    color: var(--main-color-black);
    font-size: 13px;
    line-height: 16px;

    display: flex;
    flex-flow: column;
    gap: 4px;

    .label {
      color: var(--secondary-text-color);
      padding-right: 4px;
    }
  }

  .empty-files {
    display: flex;
    flex-flow: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
  }

  .load-files {
    font-size: 12px !important;
    padding: 24px;
    box-sizing: border-box;

    :deep(.ui-loader) {
      span {
        font-size: 12px;
      }
    }
  }

  .doc-name {
    display: flex;
    flex-flow: column;
    gap: 4px;
  }

  .header-name {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 8px;
  }

  .doc-fts {
    font-size: 12px;
    line-height: 16px;
    color: var(--secondary-text-color);
  }

  .doc-title {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--main-text-color);

    &._clickable {
      color: var(--main-color-blue);
    }

    .link {
      @include link-mixin;
      overflow-wrap: anywhere;
    }
  }

  .actions {
    display: flex;
    flex-flow: row wrap;
    column-gap: 24px;
    row-gap: 8px;
    align-items: center;
  }

  ._archived {
    color: var(--main-red-color);
  }

  .adaptive-view {
    display: none;
  }
}

.lot-detail-table:not(.auth) {

  @media (max-width: 860px) {

    .adaptive-view { display: flex; }
    .full-view { display: none; }

    .grid-layout {
      grid-template-columns: 3fr minmax(176px, 2fr);
    }

    :deep(.doc-icon) {
      width: 16px;
      height: 16px;
    }
  }

  @media(max-width: 520px) {
    .info {
      font-size: 11px;
      line-height: 14px;
    }

    .grid-layout:not(.header) {
      display: flex;
      flex-flow: column;
    }
  }
}
</style>
