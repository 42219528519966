<template lang="pug">
settings-panel.multi-table-panel(:class="isAuth && 'auth'")
  .multi-tender-table
    .header.grid-layout
      .td Тендер
      .td Цена

    .table-row.grid-layout
      .td.flex
        span.tender-adaptive-label Тендер:
        span № {{ tenderInfo.govRuId }}
        span {{ tenderInfo.tenderTitle }}

      .td.flex
        span.tender-adaptive-label Цена:
        span
          template(v-if="tenderInfo?.tenderCost?.original") {{ costFormatter(tenderInfo.tenderCost.original.currency.alpha).format(tenderInfo.tenderCost.original.cost) }}
          template(v-else) Цена неизвестна
        span.converted-cost(v-if="tenderInfo?.tenderCost?.converted") ~ {{ costFormatter(tenderInfo.tenderCost.converted.currency.alpha).format(tenderInfo.tenderCost.converted.cost) }}

settings-panel.multi-table-panel(:class="isAuth && 'auth'")
  ui-loader(v-if="loader" label="Загружаем список лотов")
  no-data(v-else-if="error" text="Тендер не является многолотовым или произошла ошибка при загрузке данных")
  .multi-lot-table(v-else)
    .header.grid-layout
      .td Номер
      .td Название
      .td.purchase-label Объект закупки
      .td.full-view Цена

    .table-row.grid-layout(
      v-for="lot of data"
      :key="lot.lotId"
    )
      .td.flex
        lot-link(:id="lot.lotId" :number="lot.govRuId") Лот № {{ lot.lotNumber }}
        ui-tag(v-if="lotId === lot.lotId" name="Текущая страница" size="small" type="success")
        span.flex.row.adaptive-view
          span.adaptive-label Цена:
          span
            template(v-if="lot?.cost?.original") {{ costFormatter(lot.cost.original.currency.alpha).format(lot.cost.original.cost) }}
            template(v-else) Цена неизвестна
        span.flex.row.adaptive-view.secondary(v-if="lot?.cost?.converted")
          span.converted-cost ~ {{ costFormatter(lot.cost.converted.currency.alpha).format(lot.cost.converted.cost) }}

      .contents
        .td.flex
          span.adaptive-label Название:
          span {{ lot.lotTitle }}

        .td.purchase-info.flex
          span.adaptive-label Объект закупки:
          span {{ lot.purchaseObjects }}

      .td.full-view.flex
        span
          template(v-if="lot?.cost?.original") {{ costFormatter(lot.cost.original.currency.alpha).format(lot.cost.original.cost) }}
          template(v-else) Цена неизвестна
        span.converted-cost(v-if="lot?.cost?.converted") ~ {{ costFormatter(lot.cost.converted.currency.alpha).format(lot.cost.converted.cost) }}

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { costFormatter } from "~/utils/formatter/costFormatter";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import LotLink from "~/components/ui/links/LotLink.vue";
import SettingsPanel from "~/components/pages/settings/common/SettingsPanel.vue";
import UiTag from "~/components/ui/tag/UiTag.vue";
import NoData from "~/components/ui/table/NoData.vue";
import UiLoader from "~/components/ui/loader/UiLoader.vue";

import type { LotDetailInterface, LotItemInterface } from "~/stores/search/LotItemInterface";

export default defineComponent({
  name: "MultipleLots",
  methods: {
    costFormatter,
  },
  components: {
    UiLoader,
    NoData,
    UiTag,
    LotLink,
    SettingsPanel,
  },
  props: {
    lotId: {
      type: Number,
      default: 0,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array as PropType<LotItemInterface[]>,
      default: () => [],
    },
    tenderInfo: {
      type: Object as PropType<LotDetailInterface[]>,
      default: () => ({}),
    },
  },
  setup() {

    const { isAuth } = useUserAccess();

    return {
      isAuth,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';

.multi-table-panel {
  padding: 12px 24px;

  .multi-lot-table {
    padding: 0;

    .grid-layout {
      display: grid;
      grid-template-columns: 160px repeat(2, minmax(120px, 1fr)) 194px;
    }
  }

  .multi-tender-table {
    padding: 0;

    .grid-layout {
      display: grid;
      grid-template-columns: 1fr 194px;
    }
  }

  .contents {
    display: contents;
  }

  .flex {
    display: flex;
    flex-flow: column;
    gap: 4px;

    &.row {
      flex-flow: row;
    }
  }

  .adaptive-view {
    display: none;
  }

  .adaptive-label,
  .tender-adaptive-label {
    display: none;

    font-size: var(--detail-table--size--info);
    line-height: var(--detail-table--height--info);
    color: var(--secondary-text-color);
  }

  .secondary {
    color: var(--secondary-text-color);
  }

  .converted-cost {
    font-size: 12px;
    line-height: 16px;
    color: var(--secondary-text-color);
  }
}

.multi-table-panel:not(.auth) {

  @media (max-width: 1080px) {
    .adaptive-view { display: flex; }
    .full-view { display: none; }
    .multi-lot-table {
      .grid-layout {
        grid-template-columns: 160px repeat(2, minmax(120px, 1fr));
      }
    }
  }

  @media (max-width: 860px) {
    .adaptive-label { display: flex; }
    .purchase-label { display: none; }
    .contents {
      display: flex;
      flex-flow: column;
    }

    .multi-lot-table,
    .multi-tender-table {
      --detail-table--size--default: 13px;
      --detail-table--size--info: 13px;

      --detail-table--height--default: 17px;
      --detail-table--height--info: 17px;
    }

    .multi-lot-table {
      .grid-layout {
        grid-template-columns: 160px minmax(120px, 1fr);
        padding: 8px 0;
      }
      .td {
        padding: 4px 8px;
      }
    }
  }

  @media (max-width: 620px) {
    padding: 8px;

    .multi-lot-table,
    .multi-tender-table {
      .grid-layout {
        display: flex;
        flex-flow: column;
      }
      .header {
        display: none;
      }
    }
    .tender-adaptive-label { display: flex; }
  }
}
</style>
