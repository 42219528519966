<template lang="pug">
.no-data(v-if="!results") Данные отсутствуют
.lot-detail-table(v-else :class="isAuth && 'auth'")
  .header.grid-layout.side-padding
    .td Участник
    .td Предложенная цена
    .td.full-view % снижения
    .td.full-view Решение комиссии
  .table-row.grid-layout.side-padding(
    v-for="(supplier, index) of results"
    :key="index"
  )
    .td.participant(:class="supplier.appRating === 1 && 'winner'")
      .rating
        ui-icon(v-if="supplier.appRating === 1" :icon="UiIconNames.Landing_Trophy" :size="18")
        span(v-else) {{ supplier.appRating }}
      .column
        blurred-span(:label="supplier?.supplier ? supplier.supplier?.title : 'Участник неизвестен'")
          analytic-link(v-if="supplier.supplier" tab="suppliers" :company="supplier.supplier")
          div(v-else) Участник неизвестен
        span.adaptive-view.decline(v-if="supplier.decisionCommission")
          blurred-span(:label="supplier.decisionCommission")
    .td.column
      blurred-span(:label="costFormatterExtended(supplier.cost.original)")
      .adaptive-view.decline(v-if="supplier.decline")
        blurred-span(:label="`↓ ${ supplier.decline || 0 } %`")
    .td.full-view
      blurred-span(:label="supplier?.decline || 0")
    .td.full-view {{ supplier.decisionCommission }}
</template>

<script lang="ts">
import { defineComponent, } from "vue";
import { costFormatterExtended } from "@/utils/formatter/costFormatter";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import type { PropType } from "vue";
import type { AuctionResultsLDI, } from "@/stores/search/LotItemInterface";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";
import BlurredSpan from "~/components/other/BlurredSpan.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "9_Results",
  methods: {
    costFormatterExtended,
  },
  components: {
    BlurredSpan,
    UiIcon,
    AnalyticLink,
  },
  props: {
    results: {
      type: Array as PropType<AuctionResultsLDI[]>,
      default: () => ([]),
    }
  },
  setup() {

    const { isAuth } = useUserAccess();

    return {
      isAuth,
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';
@import '@/assets/styles/mixin/fonts';

.lot-detail-table {
  .rating {
    display: flex;
    width: 24px;
    flex-shrink: 0;

    span {
      padding-left: 4px;
    }
  }

  .adaptive-view {
    display: none;
  }

  .column {
    display: flex;
    flex-flow: column;
    gap: 4px;
  }

  .decline {
    color: var(--main-placeholder-color);
    font-size: var(--detail-table--size--info);
  }

  .participant {
    display: flex;
    flex-flow: row;
    gap: 8px;
  }

  .grid-layout {
    display: grid;
    grid-template-columns: 5fr minmax(140px, 2fr) minmax(100px, 1fr) minmax(172px, 2fr);
  }

  .winner {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--main-warning-color);

    .link {
      color: var(--main-warning-color);
    }
  }
}

.lot-detail-table:not(.auth) {

  @media (max-width: 860px) {
    .adaptive-view { display: flex; }
    .full-view { display: none; }
    .grid-layout { grid-template-columns: 5fr minmax(120px, 2fr) 0 0; }
  }

  @media(max-width: 380px) {
    overflow-wrap: anywhere;
  }
}
</style>
